/* Amatic - HTML Template  */

@media only screen and (max-width: 2000px){
	
	.fluid-section-one .content-column .owl-dots{
		right:-60px;
	}
	
}

@media only screen and (max-width: 1600px){
	
	.project-section-three .outer-container{
		padding:0px 30px;
	}
	
	.fluid-section-one .content-column .owl-dots{
		right:-20px;
	}
	
	.case-section .gallery-block{
		width:25%;
	}
	
}

@media only screen and (max-width: 1550px){
	.counter-section.style-two .inner-container,
	.counter-section .inner-container{
		margin-left:0;
		margin-right:0;
	}
	
	.counter-section.style-two .inner-container:before,
	.counter-section .inner-container:before{
		width:100%;
		border-radius:10px;
	}
	
	.fact-counter .column .inner .count-outer{
		font-size:80px;	
	}
}

@media only screen and (max-width: 1340px){
	
	.case-section .gallery-block{
		width:33.333%;
	}	
}

@media only screen and (max-width: 1140px){
	
	.main-menu .navigation > li{
		margin-right:20px;
	}
	
	.seo-section-five .image-column .image,
	.gallery-section-two .outer-container{
		padding:0px 0px;
	}
	
	.sticky-header .main-menu .navigation > li{
		margin-left:25px !important;
	}
	
	.fluid-section-one .content-column{
		padding:95px 60px 80px 15px;
	}
	
	.main-header .header-top .outer-container,
	.header-style-four .header-upper .outer-container,
	.header-style-three .header-upper .outer-container,
	.header-style-two .outer-container{
		padding:0px 15px;
	}
	
	.header-style-one{
		padding-top:20px;
	}
	
	.seo-section-five .content-column .inner-column,
	.header-style-five{
		padding-top:0px;
	}
	
	.header-style-two{
		top:20px;
	}
	
	.main-slider-two .slide-item{
		padding-top:200px;
	}
	
	.banner-section{
		padding-top:220px;
	}
	
	.seo-section-six .content-column .inner-column,
	.services-section-four .title-column .inner-column,
	.seo-section-five .content-column .inner-column,
	.contact-form-section .form-column .inner-column,
	.service-tabs-section .services-tabs .tabs-content .content-column .inner-column,
	.seo-section-three .title-column .inner-column,
	.seo-section-two .title-column .inner-column,
	.price-section .title-column .inner-column{
		padding-right:0px;
	}
	
	.seo-section-five .content-column .inner-column,
	.choose-section .image-column .inner-column,
	.seo-section-four .title-column .inner-column,
	.seo-section-three.style-two .title-column .inner-column,
	.price-section .blocks-column .inner-column{
		padding-left:0px;
	}
	
	.seo-section-five .image-column .inner-column,
	.counter-section .inner-container{
		margin-left:0px;
	}
	
	.featured-section .inner-container{
		padding-left:20px;
		padding-right:20px;
	}
	
	.service-block .inner-box h3{
		font-size:26px;
	}
	
	.counter-section.style-two .inner-container{
		margin-right:0px;
	}
	
	.seo-section-five .image-column .pattern-layer{
		display:none;
	}
	
	.seo-section-five{
		padding-bottom:0px;
	}
	
	.case-section .gallery-block{
		width:33.3333%;
	}
	
	.contact-info-section .row{
		margin:0px -15px;
	}
	
	.contact-info-section .contact-info-block{
		padding:0px 15px;
	}
	
}

@media only screen and (min-width: 768px){
	.main-menu .navigation > li > ul,
	.main-menu .navigation > li > ul > li > ul{
		display:block !important;
		visibility:hidden;
		opacity:0;
	}
}

@media only screen and (max-width: 1023px){

	.seo-section:before,
	.choose-section .image-layer,
	.main-header.header-style-one .outer-box .search-box-btn,
	.fixed-header .sticky-header{
		display: none;
	}
	
	.page-title{
		padding: 180px 0px 120px;
	}

	.page-title h1{
		font-size: 34px;
	}
	
	.main-menu .navigation > li > a{
		font-size:15px;
	}
	
	.main-header .header-top .top-left,
	.main-header .header-top .top-right{
		width:100%;
		text-align:center;
	}
	
	.main-header .header-top .info-list{
		display:inline-block;
		float:none;
	}

	.main-header .header-top .top-left .text{
		padding-bottom:0px;
	}
	
	.fixed-header .header-upper, .fixed-header .header-lower{
		position:relative;
		background:none;
	}
	
	.fixed-header .main-menu .navigation > li{
		padding:22px 0px;
	}
	
	.fixed-header .outer-box .search-box-btn{
		padding:29px 0px;
	}
	
	.fixed-header .header-upper .logo-outer{
		padding:5px 0px;
	}
	
	.page-title h2{
		font-size:50px;
	}
	
	.call-to-action-section h2{
		font-size:40px;
	}
	
	.call-to-action-section .text{
		font-size:24px;
	}
	
	.main-slider .text br,
	.header-style-three .outer-box .search-box-btn,
	.header-style-two .outer-box .search-box-btn,
	.main-header .outer-box .btn-box,
	.services-section-two .side-box,
	.fluid-section-one .content-column:before,
	.header-style-four .outer-box .search-box-btn{
		display:none;
	}
	
	.fixed-header .outer-box .nav-box{
		padding:32px 0px 24px;
	}
	
	.header-style-two .header-upper .inner-container{
		padding:0px 12px;
	}
	
	.header-style-three.fixed-header{
		background-color:#222222;
	}
	
	.main-header .header-top .top-right{
		padding:0px 0px 13px;
	}
	
	.header-style-one.fixed-header{
		padding-top:0px;
		background-color:#222222;
	}
	
	.seo-section-five .content-column .inner-column,
	.services-section-four,
	.main-slider .content{
		padding-top:0px;
	}
	
	.header-style-four.fixed-header .header-upper .logo-outer{
		padding:13px 0px;
	}
	
	.header-style-four .main-menu .navigation > li{
		padding:25px 0px;
	}
	
	.header-style-four .outer-box .nav-box{
		padding:35px 0px 26px;
	}
	
	.header-style-four .header-upper{
		background-color:rgba(255,255,255,1);
	}
	
	.fluid-section-one .image-column{
		position:relative;
		width:100%;
	}
	
	.fluid-section-one .content-column{
		position:relative;
		width:100%;
	}
	
	.fluid-section-one .image-column .image{
		display:block;
	}
	
	.services-section-two .title-box h2{
		padding-right:0px;
		border-right:none;
		text-align:left;
	}
	
	.services-section-two .title-box .text{
		padding-left:0px;
	}
	
	.services-section-two .lower-outer-section{
		margin-top:0px;
	}
	
	.seo-section-five .image-column,
	.seo-section-three .image-column{
		order:2;
	}
	
	.seo-section-five .content-column,
	.seo-section-three .content-column{
		order:1;
	}
	
	.service-tabs-section .services-tabs .tab-btns{
		border-right:none;
	}
	
	.service-tabs-section .services-tabs .tab-btns .tab-btn{
		width:25%;
	}
	
	.service-tabs-section .services-tabs .tab-btns .tab-btn:nth-child(4){
		
	}
	
	.service-tabs-section .services-tabs .tab-btns .tab-btn:last-child{
		border-right:1px solid #e5e5e5;
	}
	
	.testimonial-section .testimonial-column .inner-column{
		margin-left:0px;
	}
	
	.testimonial-section .testimonial-column .inner-column .quote-icon{
		right:10px;
	}
	
	.testimonial-section .testimonial-column .inner-column{
		padding:40px 25px;
	}
	
	.choose-section{
		padding-top:100px;
		padding-bottom:80px;
	}
	
	.fact-counter .column:nth-child(2) .inner:before{
		display:none;
	}
	
	.case-section .sec-title .pull-left,
	.case-section .sec-title .pull-right{
		width:100%;
		display:block;
	}
	
	.case-section .filter-btns{
		margin-top:30px;
	}
	
	.case-section .filter-btns li{
		margin-left:0px;
		margin-right:30px;
	}
	
	.case-section .gallery-block{
		width:50%;
	}
	
	.header-style-two.fixed-header .header-upper .logo-outer{
		padding:10px 0px;
	}
	
}

@media only screen and (max-width: 767px){

	.fact-counter .column .inner:before,
	.main-header .top-left,
	.main-header.header-style-five .outer-box .nav-box{
		display: none;
	}

	.main-header .header-upper .nav-outer{
		float:right;
		width:100%;
		padding:0px 0px 10px;
	}
	
	.main-header .main-box .logo-box{
		width: auto;
		padding: 10px 0px;
	}

	.main-header .main-menu{
		padding-top: 0px;
		width: 100%;
		margin: 0px;
	}
	
	.main-header .nav-outer .main-menu{
		display: none !important;
	}

	.main-header .nav-outer .mobile-nav-toggler{
		float:right;
		display: block;
		padding-top:0px;
	}
	
	.main-menu .navbar-header {
	    position: relative;
	    float: none;
	    display: block;
	    text-align: right;
	    width: 100%;
	    padding: 10px 0px 10px;
	    right: 0px;
	}

	.main-header .top-right > ul > li{
		padding-left: 0;
		border-left: 0;
	}
	
	.call-to-action-section .text br,
	.sec-title .text:before{
		display: none;
	}

	.main-menu .collapse {
	   max-height:400px;
		overflow:auto;
		float:none;
		width:100%;
		padding:10px 0px 0px;
		border:none;
		margin:0px 0px 15px;
		-ms-border-radius:0px;
		-moz-border-radius:0px;
		-webkit-border-radius:0px;
		-o-border-radius:0px;
		border-radius:0px;
		box-shadow:none;
   }
   
	.main-menu .collapse.in,
	.main-menu .collapsing{
		padding:10px 0px 0px;
		border:none;
		margin:0px 0px 15px;
		-ms-border-radius:0px;
		-moz-border-radius:0px;
		-webkit-border-radius:0px;
		-o-border-radius:0px;
		border-radius:0px;
		box-shadow:none;	
	}

	.navbar-dark .navbar-toggler{
		border:0;
		padding: 0;
	}

	.navbar-toggler .icon{
		position: relative;
		font-size: 28px;
		line-height: 1.2em;
		color: #ffffff;
	}

	.navbar-light .navbar-toggler{
		display: inline-block;
		z-index: 12;
		width: 50px;
		height: 40px;
		float: none;
		padding: 0px;
		text-align: center;
		border-radius: 0px;
		background: #4d273f;
		border: 1px solid #4d273f;
	}
	
	.main-menu .navbar-header .navbar-toggler .icon-bar{
		position: relative;
		background: #ffffff;
		height: 2px;
		width: 26px;
		display: block;
		margin:0 auto;
		margin:5px 11px;
	}

	.main-menu .navbar-collapse > .navigation{
		float:none !important;
		margin:0px !important;
		width:100% !important;
		border:1px solid #ffffff;
		border-top:none;
	}
	
	.main-menu .navbar-collapse > .navigation > li{
		margin:0px !important;
		float:none !important;
		padding:0px !important;
		width:100%;
	}
	
	.main-menu .navigation > li > a,
	.main-menu .navigation > li > ul:before{
		border:none;	
	}
	
	.main-menu .navbar-collapse > .navigation > li > a{
		padding:10px 10px !important;
		border:none !important;
		font-size:15px !important;
	}
	
	.main-menu .navigation li.dropdown > a:after,
	.main-menu .navigation > li.dropdown > a:before,
	.main-menu .navigation > li > ul > li > a::before,
	.main-menu .navigation > li > ul > li > ul > li > a::before{
		color:#ffffff !important;
		right:15px;
		font-size:16px;
		display:none !important;
	}
	
	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul{
		position:relative;
		border:none;
		float:none;
		visibility:visible;
		opacity:1;
		display:none;
		margin:0px;
		left:auto !important;
		right:auto !important;
		top:auto !important;
		padding:0px;
		outline:none;
		width:100%;
		background:#4d273f;
		-webkit-border-radius:0px;
		-ms-border-radius:0px;
		-o-border-radius:0px;
		-moz-border-radius:0px;
		border-radius:0px;
		transition:none !important;
		-webkit-transition:none !important;
		-ms-transition:none !important;
		-o-transition:none !important;
		-moz-transition:none !important;
		-webkit-transform: scale(1);
	    -ms-transform: scale(1);
	    -moz-transform: scale(1);
	    transform: scale(1);
		-webkit-box-shadow:none;
		-ms-box-shadow:none;
		-moz-box-shadow:none;
		box-shadow:none;
	}
		
	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul{
		border-top:1px solid rgba(255,255,255,1) !important;	
	}
	
	.main-menu .navbar-collapse > .navigation > li,
	.main-menu .navbar-collapse > .navigation > li > ul > li,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li{
		border-top:1px solid rgba(255,255,255,1) !important;
		border-bottom:none;
		opacity:1 !important;
		top:0px !important;
		left:0px !important;
		visibility:visible !important;
	}
	
	.main-menu .navbar-collapse > .navigation > li > ul > li:first-child,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:first-child{
		border-top:none !important;	
	}
	
	.main-menu .navbar-collapse > .navigation > li:first-child{
		border:none;	
	}
	
	.main-menu .navbar-collapse > .navigation > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a{
		padding:12px 20px !important;
		line-height:22px;
		color:#ffffff;
		font-weight:500;
		font-size:15px;
		background:#4d273f;
		text-align:left;
	}
	
	.main-menu .navbar-collapse > .navigation > li > a:hover,
	.main-menu .navbar-collapse > .navigation > li > a:active,
	.main-menu .navbar-collapse > .navigation > li > a:focus{
		background:#ff5926;
	}
	
	.main-menu .navbar-collapse > .navigation > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li.current > a,
	.main-menu .navbar-collapse > .navigation > li.current-menu-item > a{
		background:#4d273f;
		color:#ffffff !important;
	}
	
	.main-menu .navbar-collapse > .navigation li.dropdown .dropdown-btn{
		display:block;
	}

	.main-menu .navigation > li > ul > li > ul > li,
	.main-menu .navigation > li > ul > li{
		padding-left: 0px;
		padding-right: 0px;
	}

	.main-menu .navigation > li > ul > li > ul > li > a,
	.main-menu .navigation > li > ul > li > a{
		border-bottom: 0;
	}
	
	.main-slider h2{
		font-size:30px;
	}
	
	.main-slider .text{
		font-size:16px;
	}
	
	.sec-title .text{
		padding-right:0px;
	}
	
	.sec-title-two h2{
		font-size:32px;
	}
	
	.page-title h2{
		font-size:40px;
	}
	
	.sidebar-page-container .comments-area .comment.reply-comment{
		margin-left:0px;
	}
	
	.shop-single .comments-area .comment,
	.sidebar-page-container .comments-area .comment{
		padding-left:0px;
	}
	
	.shop-single .comments-area .comment-box .author-thumb,
	.sidebar-page-container .comments-area .comment-box .author-thumb{
		position:relative;
	}
	
	.main-slider .content h2{
		font-size:50px;
	}
	
	.sec-title h2{
		font-size:32px;
	}
	
	.main-header .outer-box{
		position:absolute;
		left:0px;
		top:10px;
	}
	
	.main-header .outer-box .search-box-btn{
		padding:0px;
		margin-left:0px;
	}
	
	.main-header .outer-box .search-box-btn .icon{
		padding-left:0px;
		border-left:none;
		padding-right:20px;
		border-right:1px solid rgba(255,255,255,0.50);
	}
	
	.header-style-three.fixed-header .header-upper .logo-outer,
	.main-header .header-upper .logo-outer{
		width:100%;
		text-align:center;
		padding:15px 0px 10px;
	}
	
	.blog-single .inner-box .lower-content .upper-box .post-meta{
		width:100%;
	}
	
	.main-header{
		position:relative;
		background-color:#007BFF;
	}
	
	.header-style-two{
		top:0px;
		background-color:#ffffff;
	}
	
	.header-style-two .outer-box .search-box-btn,
	.main-header.header-style-one .outer-box .search-box-btn{
		display:block;
	}
	
	.main-header .outer-box .nav-box{
		padding:0px;
		margin-left:25px;
	}
	
	.header-style-one{
		padding-top:0px;
	}
	
	.header-style-two .header-upper .inner-container{
		padding:0px;
	}
	
	.header-style-five .outer-box .search-box-btn .icon,
	.header-style-four .outer-box .search-box-btn .icon,
	.header-style-two .outer-box .search-box-btn .icon{
		border-right-color:rgba(0,0,0,0.20);
	}
	
	.header-style-four .nav-outer .mobile-nav-toggler,
	.header-style-two .nav-outer .mobile-nav-toggler{
		color:#222222;
	}
	
	.header-style-four .outer-box .search-box-btn,
	.header-style-three .outer-box .search-box-btn{
		display:block;
	}
	
	.header-style-three.fixed-header .outer-box .search-box-btn,
	.header-style-three.fixed-header .outer-box .nav-box{
		padding:0px;
	}
	
	.main-header .header-top .top-right{
		padding:13px 0px;
	}
	
	.header-style-five{
		position:relative;
		background-color:#ffffff;
		border-bottom:1px solid #e0e0e0;
	}
	
	.header-style-five.fixed-header .header-upper{
		background-color:#ffffff;
		border-bottom:none;
	}
	
	.header-style-five .nav-outer .mobile-nav-toggler,
	.header-style-five .outer-box .search-box-btn{
		color:#222222;
	}
	
	.header-style-five .outer-box .nav-box .nav-btn .icon{
		background-color:#222222;
	}
	
	.main-slider .slide-item{
		padding-top:120px;
	}
	
	.main-slider-two .content{
		padding-top:0px;
	}
	
	.main-slider-two .slide-item{
		padding-top:150px;
	}
	
	.main-slider.style-two .slide-item{
		padding-top:120px;
		padding-bottom:350px;
	}
	
	.header-style-four.fixed-header .header-upper .logo-outer{
		padding:15px 0px 10px;
	}
	
	.main-slider.style-three .slide-item{
		padding:120px 0px 220px;
	}
	
	.service-tabs-section .services-tabs .tab-btns .tab-btn{
		width:50%;
	}
	
	.header-style-four.fixed-header .header-upper{
		position:relative !important;
	}
	
	.testimonial-block-two .inner-box .text{
		font-size:18px;
	}
	
	.banner-section{
		padding-top:120px;
	}
	
	.testimonial-block-three .inner-box{
		padding-left:0px;
	}
	
	.testimonial-block-three .inner-box .image-outer{
		position:relative;
		margin-bottom:0px;
		margin-top:20px;
	}
	
	.testimonial-section-two .inner-container .testimonial-outer{
		padding-right:0px;
	}
	
	.testimonial-section-two .owl-dots{
		position:relative;
		top:0px;
		left:0px;
		margin-top:20px;
	}
	
	.testimonial-section-two{
		padding:110px 0px 100px;
	}
	
	.page-title{
		padding:100px 0px 100px;
	}
	
	.case-section .gallery-block{
		width:100%;
	}
	
	.case-info-block .inner-box{
		border-right:none;
	}
	
	.case-detail-section .image{
		margin-bottom:30px;
	}
	
	.case-detail-section h2{
		font-size:36px;
	}
	
	.case-detail-section{
		padding-top:100px;
	}
	
	.banner-section .pattern-layer{
		display:none;	
	}
	
	.banner-section{
		padding-top:80px;
		padding-bottom:80px;
		text-align:center;	
	}
	
	.banner-section .image-column,
	.banner-section .banner-icons,
	.main-slider .image-column,
	.main-slider-two .image-column{
		display:none;	
	}
	
	.banner-section .title-column h2{
		font-size:36px;
		text-transform:capitalize;
		line-height:1.2em;
	}
	
	.banner-section .title-column h2 br{
		display:none;	
	}
	
	.main-slider,
	.main-slider-two{
		text-align:center;	
	}
	
	.main-slider .slide-item{
		padding-bottom:100px;	
	}
	
	.main-slider-two .slide-item{
		padding-bottom:140px;	
	}
	
	.banner-section .title-column .text{
		margin:20px 0 40px;
		max-width:none;
	}
	
}

@media only screen and (max-width: 599px){
	
	.main-slider .content h2{
		font-size:40px;
	}
	
	.main-slider .text{
		margin-top:20px;
		margin-bottom:20px;
	}
	
	.case-section .filter-btns li{
		margin-right:20px;
	}
	
	.main-slider-two .content h2{
		font-size:46px;
	}
	
	.main-slider-two .content .text{
		font-size:17px;
	}
	
	.blog-single .inner-box .lower-content h2,
	.case-detail-section h2,
	.sec-title h2{
		font-size:28px;
	}
	
	.main-slider .content h2 br{
		display:none;
	}
	
	.search-popup .search-form fieldset input[type="search"]{
		padding:15px 20px;
		border-radius:5px 5px 0px 0px;
	}
	
	.search-popup .search-form fieldset input[type="submit"]{
		position:relative;
		width:100%;
		border-radius:0px 0px 5px 5px;
	}
	
	.sidebar-textwidget{
		padding:40px 20px;
	}
	
	.website-form .form-group{
		margin-right:0px;
	}
	
	.website-form .theme-btn{
		position:relative;
		width:100%;
		display:block;
	}
	
	.banner-section .title-column .text{
		font-size:16px;
	}
	
	.choose-section .image-column .image img,
	.seo-section .image-column .image img{
		max-width:100%;
	}
	
	.fluid-section-one .content-column{
		padding-right:15px;
	}
	
	.fluid-section-one .content-column .owl-dots{
		position:relative;
		right:0px;
		top:auto;
		text-align:center;
		margin-top:20px;
	}
	
	.fluid-section-one .content-column .owl-dots .owl-dot{
		display:inline-block;
		margin:0px 5px;
	}
	
	.main-footer .footer-bottom .pull-left,
	.main-footer .footer-bottom .pull-right{
		width:100%;
		text-align:center;
	}
	
	.main-footer .footer-bottom .footer-list{
		margin-top:6px;
	}
	
	.services-section-two .title-box h2{
		font-size:35px;
	}
	
	.seo-section-two .video-column .inner-column{
		padding-right:0px;
	}
	
	.seo-section-two .video-column .inner-column .image,
	.seo-section-two .video-column .inner-column .image img{
		width:100%;
	}
	
	.sec-title h2 br,
	.seo-section-two .video-column .inner-column .play-box{
		display:none;
	}
	
	.gallery-section .outer-container .owl-nav{
		position:relative;
		top:0px;
		margin-top:20px;
	}
	
	.gallery-section{
		padding:100px 0px 100px;
	}
	
	.choose-section-two .images-column .image-two{
		left:0px;
	}
	
	.faq-page-section .title-box h2{
		font-size:36px;
	}
	
	.comment-form,
	.blog-single .inner-box .lower-content,
	.accordion-box .block .acc-btn,
	.accordion-box .block .content,
	.sidebar-widget .widget-content{
		padding-left:20px;
		padding-right:20px;
	}
	
	.blog-single .post-share-options .post-share-inner .pull-left,
	.blog-single .post-share-options .post-share-inner .pull-right{
		width:100%;
		display:block;
	}
	
	.blog-single .post-share-options .social-icons{
		margin-top:18px;
	}
	
}

@media only screen and (max-width: 479px) {
	
	.main-slider .content h2{
		font-size:30px;
	}
	
	.accordion-box .block .icon-outer{
		right:20px;
	}
	
	.btn-style-four{
		padding:15px 32px;
	}
	
	.accordion-box .block .acc-btn{
		font-size:22px;
	}
	
	.gallery-block .inner-box .image .overlay-box .text{
		max-width:100%;
	}
	
	.website-form .form-group{
		border-radius:0px;
		margin-bottom:0px;
	}
	
	.service-block-two .inner-box,
	.offer-block .inner-box{
		padding-left:15px;
		padding-right:15px;
	}
	
	.news-block-two .inner-box,
	.news-block .inner-box .lower-content,
	.service-block .inner-box,
	.price-block .inner-box{
		padding-left:20px;
		padding-right:20px;
	}
	
	.newsletter-form .form-group{
		padding-right:0px;
	}
	
	.newsletter-form .form-group button{
		position:relative;
		width:100%;
		margin-top:15px;
	}
	
	.banner-section .website-form .form-group{
		border:none;
		margin-bottom:0px;
	}
	
	.website-form .form-group input{
		width:100%;
		border-radius:50px;
		margin-bottom:20px;
	}
	
	.choose-section-two .images-column .image-two,
	.service-tabs-section .services-tabs .image-column .image-two,
	.seo-section-three .image-column .image-two{
		position:relative;
		bottom:0px;
		margin-top:25px;
		display:inline-block;
	}
	
	.seo-section-three .image-column .image-three{
		position:relative;
		bottom:0px;
		display:inline-block;
	}
	
	.service-tabs-section .services-tabs .tab-btns .tab-btn{
		width:100%;
	}
	
	.seo-section-four .video-column .inner-column .video-box{
		position:relative;
		margin-top:25px;
	}
	
	.featured-block .inner-box .content{
		padding-left:0px;
	}
	
	.featured-block .inner-box .content .icon{
		position:relative;
	}
	
	.gallery-block .inner-box .image .overlay-box .overlay-inner{
		left:20px;
		right:20px;
	}
	
}